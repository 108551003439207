<template>
  <div style="min-height: 75vh">
    <div class="d-flex my-2">
      <b-form inline class="search-app">
        <b-form-input
          type="search"
          v-model="pub_name"
          class="w-75"
          placeholder="Search application by publication"
        ></b-form-input>
      </b-form>
      <div>
        <b-button
          variant="outline-danger"
          class="ml-auto"
          @click="refresh"
          size="sm"
        >
          <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
        </b-button>
      </div>
    </div>
    <b-table
      :fields="fields"
      :items="applications"
      thead-class="d-none"
      :filter="pub_name"
      hover
      responsive
      :busy="isBusy"
      empty-text="No applications yet"
      table-variant="light"
      @row-clicked="onRowClick"
      class="applications-table"
            :per-page="perPage"
      :current-page="currentPage"
      size="sm"
       :filter-function="filterTable"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(payment_status)="data">
        <b-badge
          :variant="data.item.payment_status | paint_payment"
          class="text-uppercase"
          >{{ data.item.payment_status | fmt_pay }}</b-badge
        >
      </template>
      <template #cell(publication)="data">
        <div class="application-title">
          {{ data.item.publications | fmt_title }}
        </div>
      </template>
      <template #cell(actions)="data">
        <b-button
          variant="link"
          @click="dropApp(data.item.id)"
          size="sm"
          :disabled="!canDelete(data.item.status)"
        >
          <b-icon-trash
            :class="{ 'text-muted': !canDelete(data.item.status) }"
            class="text-danger"
          ></b-icon-trash>
        </b-button>
      </template>
    </b-table>
        <b-pagination
        class="mx-auto w-25"
      v-model="currentPage"
      :total-rows="applications.length"
      :per-page="perPage"
      aria-controls="my-table"
    ></b-pagination>
  </div>
</template>

<script>
import moment from "moment";
import AppMixin from "./mixins";
import ListApplicationsMixin from "../shared/ListApplicationsMixin";
import { APP_TYPES, STORES } from "../shared";
export default {
  mixins: { AppMixin, ListApplicationsMixin },
  filters: {
    fmt_title(publications) {
      if (publications.length == 1) return publications[0]["tentative_title"];
      if (publications.length > 1)
        return publications[0]["tentative_title"] + `+ ${publications.length-1} more`;
    },
  },
  computed:{
    fields(){
      const status = this.$route.query["status"];
      const items =  [
        { key: "drn", class: "font-weight-bold" },
        "publication",
        "payment_status",
        {
          key: "created_at",
          formatter: (cdate) => {
            return moment(cdate).format("MMM DD");
          },
        },
        "actions",
      ];
      if(status==undefined){
        items.splice(2,0,{key:"status",
        class:['status-text'],
        formatter:(cstatus)=>{return cstatus.toUpperCase();}});
      }
      return items;
    }
  },
  data() {
    return {
      pub_name:"",
      currentPage:1,
      perPage:7,
      type: APP_TYPES.ISBN,
    };
  },
  methods: {
    /**
     * 
     * @param {{publications:Array<{tentative_title:string}>}} row 
     * @param {string} filter 
     */
    filterTable(row,filter){
      const rgx = new RegExp(filter.toLowerCase()+'*','g');
      if(row.publications.find(el=>rgx.test(el.tentative_title.toLocaleLowerCase()))){
        return true;
      }
      return false;
    },
    onRowClick(item) {
      const status = this.$route.query["status"];
      let baseQ = {
        params: { application_id: item.id },
        query: { status },
        name: "application",
      };

      this.$router.push(baseQ);
    },
    dropApp(item_id) {
      this.dropApplication(item_id).then((status) => {
        if (status) {
          this.$store.dispatch(STORES[this.type] + "/dropApplication", item_id);
        }
      });
    }
  },
};
</script>

<style scoped>
.application-title {
  width: 40ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-app {
  width: 66%;
}
.drop-app {
  font-size: 0.5em;
}
</style>
<style>
.applications-table > table > tbody > tr {
  border-bottom-width: 0.5%;
  border-bottom-color: rgba(0, 0, 0, 0.05);
  border-bottom-style: solid;
}
.status-text{
  font-size:small;
  font-weight:bold;
  vertical-align: bottom;
  line-height: 1.8em;
}
</style>
